import { lazy } from "react";
import { Navigate } from "react-router";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// dashboard routing
const DashboardPage = Loadable(lazy(() => import("views/dashboard/default")));

const MyOrdersPage = Loadable(lazy(() => import("views/dashboard/my-orders")));
const MyOrderFormPage = Loadable(lazy(() => import("views/dashboard/my-orders/MyOrderForm")));

// ==============================|| USER ROUTING ||============================== //

const UserRoutes = {
    path: "/admin",
    element: <MainLayout privateRoute />,
    children: [
        {
            path: "/",
            element: <Navigate to="/admin/dashboard" />,
        },
        {
            path: "/dashboard",
            element: <DashboardPage />,
        },
        {
            path: "/my-orders",
            element: <MyOrdersPage />,
        },
        {
            path: "/my-orders/:orderId",
            element: <MyOrderFormPage />,
        },
    ],
};

export default UserRoutes;
