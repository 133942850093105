import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// project imports
import { initUserRedux } from "store/actions/auth";

// ==============================|| USER HOOKS  ||============================== //

const useInitUser = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = async () => initUserRedux(dispatch);
        fetch();
    }, [dispatch]);
};

const useGetRoles = () => {
    const auth = useSelector((state) => state.authentication);
    return auth.roles;
};

export { useInitUser, useGetRoles };
