import { lazy } from "react";
import { Navigate } from "react-router";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// dashboard routing
const DashboardPage = Loadable(lazy(() => import("views/dashboard/default")));

// managment routing
const UsersLocationPage = Loadable(lazy(() => import("views/dashboard/admin/users-location")));
const UserListPage = Loadable(lazy(() => import("views/dashboard/admin/users")));
const AdminSettingsPage = Loadable(lazy(() => import("views/dashboard/admin/settings")));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import("views/utilities/Typography")));
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(lazy(() => import("views/utilities/MaterialIcons")));
const UtilsTablerIcons = Loadable(lazy(() => import("views/utilities/TablerIcons")));

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
    path: "/admin",
    element: <MainLayout privateRoute />,
    children: [
        {
            path: "/",
            element: <Navigate to="/admin/dashboard" />,
        },
        {
            path: "/dashboard",
            element: <DashboardPage />,
        },
        {
            path: "/users",
            element: <UserListPage />,
        },
        {
            path: "/location-users",
            element: <UsersLocationPage />,
        },
        {
            path: "/platform-settings",
            element: <AdminSettingsPage />,
        },
        {
            path: "/utils/util-color",
            element: <UtilsColor />,
        },
        {
            path: "/utils/util-shadow",
            element: <UtilsShadow />,
        },
        {
            path: "/icons/tabler-icons",
            element: <UtilsTablerIcons />,
        },
        {
            path: "/icons/material-icons",
            element: <UtilsMaterialIcons />,
        },
    ],
};

export default AdminRoutes;
