const generateModelRoutesCRUD = (reactModelName, apiModelName) => ({
    [`GET_${reactModelName}S`]: {
        url: `/${apiModelName}`,
        method: "GET",
    },
    [`GET_${reactModelName}`]: ({ id }) => ({
        url: `/${apiModelName}/${id}`,
        method: "GET",
    }),
    [`SAVE_${reactModelName}`]: ({ id }) => ({
        url: `/${apiModelName}${id != null ? `/${id}` : ""}`,
        method: id != null ? "PUT" : "POST",
    }),
    [`DELETE_${reactModelName}`]: ({ id }) => ({
        url: `/${apiModelName}/${id}`,
        method: "DELETE",
    }),
});

export { generateModelRoutesCRUD };
