// assets
import { IconDashboard, IconHanger, IconHistory, IconLicense } from "@tabler/icons";

// constant
const icons = { IconDashboard, IconHanger, IconHistory, IconLicense };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: "dashboard",
    title: "Panel de Control",
    type: "group",
    children: [
        // {
        //     id: "default",
        //     title: "Inicio",
        //     type: "item",
        //     url: "/admin/dashboard",
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false,
        // },
        {
            id: "my-orders",
            title: "Mis Pedidos",
            type: "item",
            url: "/admin/my-orders",
            icon: icons.IconLicense,
            breadcrumbs: false,
        },
        // {
        //     id: "history",
        //     title: "Histórico",
        //     type: "item",
        //     url: "/admin/history",
        //     icon: icons.IconHistory,
        //     breadcrumbs: false,
        // },
    ],
};

export default dashboard;
