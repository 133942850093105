import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import "moment/locale/es";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import Loader from "components/Loader";
import NavigationScroll from "layout/Navigation/NavigationScroll";
import { useInitUser } from "hooks/user";
import { SnackbarProvider } from "providers/SnackbarProvider";

// ==============================|| APP ||============================== //

const App = () => {
    useInitUser();
    const { authentication: auth, customization } = useSelector((state) => state);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={DateAdapter} locale="es">
                    <SnackbarProvider>
                        <CssBaseline />
                        <NavigationScroll>
                            {auth.isLogged && !auth.roles ? <Loader /> : <Routes />}
                        </NavigationScroll>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
