import { LOGIN } from "store/actions/types";
import { axios } from "services/api";
import { actions } from "constants/api";
import { recoverSession, decodeJWT } from "services/auth";

export const initUserRedux = async (dispatch, token = recoverSession()) => {
    if (!token) return;

    try {
        const { data } = await axios({ ...actions.GET_USER_ME, headers: { authorization: `Bearer ${token}` } });
        const user = {
            ...decodeJWT(token),
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            accessToken: token,
        };
        dispatch({ type: LOGIN, payload: user });

        return user;
    } catch (error) {}
};
