const chunkArray = (array, length = 2) => {
    var chunkLength = Math.max(array.length / length, 1);
    var chunks = [];
    for (var i = 0; i < length; i++) {
        if (chunkLength * (i + 1) <= array.length)
            chunks.push(array.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
};

const isIterable = (obj) => {
    // checks for null and undefined
    if (obj == null) return false;
    return typeof obj[Symbol.iterator] === "function";
};

const mergueArray = (array) => array.reduce((prev, curr) => [...prev, ...curr], []);

export { chunkArray, isIterable, mergueArray };
