import { createStore } from "redux";
import reducer from "./reducer";

// TODO: REMOVE REDUX DEVTOOLS EXTENSION ON PRODUCTION
const reduxDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, reduxDevToolsExtension);
const persister = "Kubas";

export { store, persister };
