const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead
    clientId: "760837047172-u5ppa1lrohbsfa92dqro6v05312gdave.apps.googleusercontent.com",
    baseURL: "https://kubas.io/api/public/index.php", // "https://kubas.io/api/public/index.php", // "https://kubas.io",
    basename: "",
    defaultPath: "/home",
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 7,
};

export default config;
