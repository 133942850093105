import { createContext, useState } from "react";

// material-ui
import { Snackbar, Alert } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({});

    const showMessage = (message, severity = "info") => {
        setSnackbar({ message, severity, open: true });
    };

    const iconMapping = {
        success: <CheckCircleOutlineIcon fontSize="inherit" />,
        error: <ErrorOutlineIcon fontSize="inherit" />,
    };

    const onClose = (_event, reason) => {
        if (reason !== "clickaway") setSnackbar({ open: false });
    };

    return (
        <SnackbarContext.Provider value={{ showMessage }}>
            {children}
            <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={onClose}>
                <Alert severity={snackbar.severity} iconMapping={iconMapping} variant="filled">
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export { SnackbarProvider, SnackbarContext };
