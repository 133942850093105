// material-ui
import { Box, Divider, Typography as MUITypography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// components
import IconTypography from "components/extended/IconTypography";

// assets
import {
    IconNotes,
    IconLicense,
    IconSearch,
    IconMap,
    IconTag,
    IconHanger,
} from "@tabler/icons";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

// styles
const Typography = styled(MUITypography)({ fontSize: "0.91rem" });

const HelpSection = ({ title, children, icon }) => (
    <Stack spacing={1}>
        <IconTypography variant="h2" startIcon={icon}>
            {title}
        </IconTypography>
        <Divider />
        <Box sx={{ padding: "5px 0" }}>{children}</Box>
    </Stack>
);

const MyOrderHelpSection = () => (
    <HelpSection title="Mis Pedidos" icon={<IconLicense />}>
        <Typography variant="body1">
            El apartado de <span style={{ fontStyle: "italic" }}>"Mis pedidos"</span> le permite ver un listado con
            todos los pedidos que usted ha realizado en la plataforma, permitiendo filtrar por año, mes o
            trimestre.
            <br />
            <br />
            &nbsp;Además de ver los detalles de los pedidos que has ido realizando, en caso de tener un pedido
            activo que ya ha terminado de utilizar, podrá realizar una <strong>solicitud de retirada</strong> al
            proveedor indicándole que ya puede venir a realizar la retirada de la cuba.
        </Typography>
    </HelpSection>
);

const NearbySearchHelpSection = () => (
    <HelpSection title="Búsqueda por área" icon={<IconSearch />}>
        <Typography component="div" variant="body1">
            Esta búsqueda muestra un mapa donde se pueden observar la ubicación de todos los proveedores
            disponibles mediante los marcadores rojos, en caso de usted ser también proveedor su marcador aparecerá
            en azul.
            <br />
            <br />
            &nbsp;El mapa permite realizar las siguientes acciones:
            <br />
            <ul>
                <li>
                    Hacer clic en los diferentes marcadores del mapa se mostrarán en la parte inferior los
                    productos que oferta el proveedor seleccionado. Si desea dejar de ver los productos del
                    proveedor seleccionado, haga nuevamente clic en el marcador seleccionado anteriormente.
                </li>
                <br />
                <li>
                    Hacer clic en el mapa coloca un área circular de búsqueda donde se mostrarán en la parte
                    inferior todos los productos que ofrecen los proveedores que se encuentren dentro del área
                    seleccionada. El tamaño del área se puede ajustar con el selector que se encuentra en la parte
                    inferior derecha.
                </li>
            </ul>
            Por último si desea quitar el área de búsqueda en la parte inferior izquierda aparecerá un botón para
            realizar dicha acción.
        </Typography>
    </HelpSection>
);

const GeolocationHelpSection = () => (
    <HelpSection title="Geolocalización" icon={<IconMap />}>
        <Typography variant="body1">
            Indique haciendo clic en el mapa la ubicación en la que te encuentras. Esta ubicación
            <strong> en caso de ser un proveedor</strong>
            <span style={{ fontStyle: "italic" }}> (tener productos creados)</span> se utilizará para que los demás
            usuarios puedan ver en el mapa del inicio donde te encuentras y puedan consultar sus productos más
            fácilmente.
            <br />
            <br />
            Además será la ubicación utilizada <strong>por defecto</strong> para todas las operaciones que
            requieran indicar la ubicación, como por ejemplo realizar un pedido.
        </Typography>
    </HelpSection>
);

const OrderHelpSection = () => (
    <HelpSection title="Pedidos" icon={<IconNotes />}>
        <Typography component="div" variant="body1">
            Esta sección muestra un listado con todos los pedidos que realizan los clientes. Una vez el cliente
            haya completado el pedido introduciendo todos los datos aparecerán en este listado. Haciendo clic en el
            listado entrarás dentro del pedido donde se pueden observar tanto los detalles del pedido como del
            cliente que lo realizó. Es posible cambiar tanto el producto como las fechas de entrega/recogida del
            pedido en caso de que fuese necesario. También permite llevar el control del estado del pedido,
            permitiendo marcar el pedido como entregado/recogido. <br />
            <br />
            Cada estado indica lo siguiente:
            <br />
            <ul>
                <li>
                    <strong>En espera</strong>: Indica que el pedido aún no ha sido entregado y además la fecha de
                    entrega aún no ha llegado.
                </li>
                <li>
                    <strong>Pendiente de entrega</strong>: Indica que se ha superado la fecha de entrega y el
                    pedido aún no ha sido entregado.
                </li>
                <li>
                    <strong>Entregado</strong>: Indica que el pedido ha sido entregado y además la fecha de
                    retirada aún no ha llegado.
                </li>
                <li>
                    <strong>Pendiente de retirar</strong>: Indica que se ha superado la fecha de retirada y el
                    pedido aún no ha sido entregado.
                </li>
                <li>
                    <strong>Retirado</strong>: Indica que el pedido se ha retirado y por lo tanto se ha completado.
                </li>
            </ul>
            El cliente en caso de ser necesario podrá
            <strong> solicitar la retirada de manera anticipada</strong>, esta solicitud será representada en el
            listado de los pedidos mediante una advertencia en el mismo.
            <br />
            En caso de necesitar comunicarte con el cliente puedes mandar un correo electrónico desde el listado de
            los pedidos.
        </Typography>
    </HelpSection>
);

const DistancePriceHelpSection = () => (
    <HelpSection title="Precio por distancia" icon={<IconTag />}>
        <Typography variant="body1">
            Indica el porcentaje que debe de incrementar el precio dado un rango de kilómetros.
            <br />
            La distancia se calcula midiendo en línea recta
            <span style={{ fontStyle: "italic" }}> (próximamente mediante rutas) </span> desde la ubicación
            indicada en el producto hasta la ubicación seleccionada por el cliente en el momento de realizar el
            pedido.
            <br />
            Esta opción le permite establecer el rango máximo de kilómetros a los que usted da servicio, evitando
            así recibir pedidos con puntos de entrega demasiado lejanos.
        </Typography>
    </HelpSection>
);

const MyProductHelpSection = () => (
    <HelpSection title="Productos" icon={<IconHanger />}>
        <Typography component="div" variant="body1">
            Este apartado muestra todos los productos que tienes disponibles para su alquiler.
            <strong> Una vez creas tu primer producto se te asignará el rol de vendedor</strong>, habilitando en la
            plataforma
            <strong> nuevas funcionalidades </strong>
            para realizar la gestión de los pedidos. Cada producto cuenta con una localización la cual será
            utilizada para incrementar el precio según la distancia en caso de tenerlo configurado.
            <br />
            <br />
            Además el producto cuenta con los siguientes tipos de precios:
            <br />
            <ul>
                <li>
                    <strong>Precio por tipo uso</strong>: Permite indicar diferentes precios dependiendo del uso
                    que se le vaya a dar al producto.
                </li>
                <li>
                    <strong>Precio de alquiler</strong>: Indica el precio base del producto por ser alquilado.
                </li>
                <li>
                    <strong>Incremento precio por día</strong>: Permite incrementar el precio por cada día que se
                    exceda de un determinado número de días de alquiler.&nbsp;
                    <span style={{ fontStyle: "italic" }}>
                        Ej. A partir del quinto día de alquiler incrementar 15 € por cada día que se exceda.
                    </span>
                </li>
            </ul>
        </Typography>
    </HelpSection>
);

const ClientHelpSection = () => (
    <HelpSection title="Clientes" icon={<AssignmentIndIcon />}>
        <Typography variant="body1">
            En el listado de clientes se muestran todos los usuarios que alguna vez le haya realizado algún pedido.
            Puede hacer clic en el listado para ver los datos con detalle de cada cliente.
        </Typography>
    </HelpSection>
);

export {
    MyOrderHelpSection,
    NearbySearchHelpSection,
    GeolocationHelpSection,
    OrderHelpSection,
    DistancePriceHelpSection,
    MyProductHelpSection,
    ClientHelpSection,
};
