import { lazy } from "react";
import { Navigate } from "react-router";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// order routing
const WorkOrderPage = Loadable(lazy(() => import("views/dashboard/work-orders")));
const WorkOrderFormPage = Loadable(lazy(() => import("views/dashboard/work-orders/WorkOrderForm")));

// ==============================|| DRIVER ROUTING ||============================== //

const DriverRoutes = {
    path: "/admin",
    element: <MainLayout privateRoute />,
    children: [
        {
            path: "/",
            element: <Navigate to="/admin/dashboard" />,
        },
        {
            path: "/work-orders",
            element: <WorkOrderPage />,
        },
        {
            path: "/work-order/:orderId",
            element: <WorkOrderFormPage />,
        },
    ],
};

export default DriverRoutes;
