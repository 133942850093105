import config from "config";
import { generateModelRoutesCRUD } from "utils/api";

const actions = {
    LOGIN_AS: ({ userId }) => ({
        url: `/admin/login/${userId}`,
        method: "PUT",
    }),
    LOGIN: {
        url: "/login",
        method: "PUT",
    },
    REGISTER: {
        url: "/register",
        method: "POST",
    },
    GET_SITE_KEY: {
        url: "/register/captcha/sitekey",
        method: "GET",
    },
    GET_RESET_PASSWORD_SITE_KEY: {
        url: "/user/sendPasswordRestore/captcha/sitekey",
        method: "GET",
    },
    SEND_RESET_PASSWORD: {
        url: "/user/sendPasswordRestore",
        method: "POST",
    },
    RESET_PASSWORD: ({ token }) => ({
        url: `/user/passwordRestore/${token}`,
        method: "PUT",
    }),
    SAVE_PRODUCT: ({ id }) => ({
        url: `/product${id != null ? `/${id}` : ""}`,
        method: id != null ? "PUT" : "POST",
    }),
    DELETE_PRODUCT: ({ id }) => ({
        url: `/product/${id}`,
        method: "DELETE",
    }),
    GET_HOME: {
        url: "/home",
        method: "GET",
    },
    GET_HOME_FILTERS: {
        url: "/home/filters",
        method: "GET",
    },
    GET_USER_ME: {
        url: "/user/me",
        method: "GET",
    },
    GET_USER: ({ userId }) => ({
        url: `/user/${userId}`,
        method: "GET",
    }),
    GET_USERS_POSITION: {
        url: "/user/position",
        method: "GET",
    },
    GET_PRODUCT: ({ id }) => ({
        url: `/product/${id}`,
        method: "GET",
    }),
    UPLOAD_PRODUCT_IMAGE: ({ productId }) => ({
        url: `/product/${productId}/photo`,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
    }),
    DELETE_PRODUCT_IMAGE: ({ productId, imageId }) => ({
        url: `/product/${productId}/photo/${imageId}`,
        method: "DELETE",
    }),
    GET_PRODUCT_IMAGE: ({ productId, imageId }) => `${config.baseURL}/product/${productId}/photo/${imageId}`,
    GET_CATEGORIES: {
        url: "/category",
        method: "GET",
    },
    GET_CATEGORY: ({ id }) => ({
        url: `/category/${id}`,
        method: "GET",
    }),
    GET_CATEGORY_IMAGE: ({ categoryId }) => `${config.baseURL}/category/${categoryId}/photo`,
    EDIT_USER_ME: {
        url: "/user/me",
        method: "PUT",
    },
    GET_BUY_INFO: ({ productId }) => ({
        url: `/product/${productId}/buy/info`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
    }),
    BUY_PRODUCT: ({ productId }) => ({
        url: `/product/${productId}/buy`,
        method: "POST",
    }),
    CREATE_ORDER_PAYPAL: {
        url: "/api/paypal/createOrder",
        method: "POST",
    },
    PAY_ORDER_PAYPAL: ({ orderId }) => ({
        url: `/order/${orderId}/pay`,
        method: "PUT",
    }),
    SEARCH_PRODUCT: {
        url: "/product/search",
        method: "GET",
    },
    NEARBY_SELLER_USER: {
        url: "/user/sellerNearby",
        method: "GET",
    },
    GOOGLE_JWT: {
        url: "/connect/google",
        method: "PUT",
    },
    VERIFY_EMAIL: ({ token }) => ({
        url: `/user/confirm/${token}`,
        method: "PUT",
    }),
    RESEND_VERIFY_EMAIL: {
        url: "/user/resendConfirmEmail",
        method: "POST",
    },

    /* ADMIN */
    GET_USERS: {
        url: "/user",
        method: "GET",
    },

    GET_USER_REGISTERED: ({ nif }) => ({
        url: `/seller/user/${nif}`,
        method: "GET",
    }),

    /* CUSTOMER MANAGMENT */
    ...generateModelRoutesCRUD("CUSTOMER", "client"),

    GET_USER_CLIENTS: {
        url: `/user/clients`,
        method: "GET",
    },

    GET_COUNTRIES: {
        url: `/countries`,
        method: "GET",
    },

    // GET_CUSTOMERS: {
    //     url: "/client",
    //     method: "GET",
    // },
    // GET_CUSTOMER: ({ customerId }) => ({
    //     url: `/client/${customerId}`,
    //     method: "GET",
    // }),
    // SAVE_CUSTOMER: ({ id }) => ({
    //     url: `/client${id != null ? `/${id}` : ""}`,
    //     method: id != null ? "PUT" : "POST",
    // }),
    // DELETE_CUSTOMER: ({ id }) => ({
    //     url: `/client/${id}`,
    //     method: "DELETE",
    // }),

    /* DRIVER MANAGMENT */
    ...generateModelRoutesCRUD("DRIVER", "driver"),

    // GET_DRIVERS: {
    //     url: "/driver",
    //     method: "GET",
    // },
    // GET_DRIVER: ({ driverId }) => ({
    //     url: `/driver/${driverId}`,
    //     method: "GET",
    // }),
    // SAVE_DRIVER: ({ id }) => ({
    //     url: `/driver${id != null ? `/${id}` : ""}`,
    //     method: id != null ? "PUT" : "POST",
    // }),
    // DELETE_DRIVER: ({ id }) => ({
    //     url: `/driver/${id}`,
    //     method: "DELETE",
    // }),

    /* ORDERS MANAGMENT */
    GET_ORDERS: {
        url: "/sales",
        method: "GET",
        // params: { page: 0 },
    },
    GET_ORDER: ({ orderId }) => ({
        url: `/sales/${orderId}`,
        method: "GET",
    }),
    GET_MY_ORDERS: {
        url: "/order",
        method: "GET",
    },
    GET_MY_ORDER: ({ orderId }) => ({
        url: `/order/${orderId}`,
        method: "GET",
    }),
    GET_WORK_ORDERS: {
        url: "/driver/orders",
        method: "GET",
    },
    GET_WORK_ORDER: ({ orderId }) => ({
        url: `/driver/order/${orderId}`,
        method: "GET",
    }),
    ASSIGN_DRIVER_ORDER: ({ orderId }) => ({
        url: `/order/${orderId}/drivers`,
        method: "PUT",
    }),
    UPDATE_ORDER_DELIVERY_STATE: ({ orderId }) => ({
        url: `/order/${orderId}/delivery`,
        method: "PUT",
    }),
    SAVE_ORDER: ({ orderId }) => ({
        url: `/order${orderId != null ? `/${orderId}` : ""}`,
        method: orderId != null ? "PUT" : "POST",
    }),
    REQUEST_WITHDRAW: ({ orderId }) => ({
        url: `/order/${orderId}/requestWithdraw`,
        method: "PUT",
    }),
    CREATE_INVOICE: {
        url: "/invoice",
        method: "POST",
    },
    GET_INVOICES: {
        url: "/invoice",
        method: "GET",
    },
    SEND_EMAIL_CUSTOMER_ORDER: ({ orderId }) => ({
        url: `/order/${orderId}/notify`,
        method: "PUT",
    }),

    /* ORDER REPORTS */
    ORDER_GEOLOCATION_REPORT: {
        // url: "/order/report/geolocation",
        url: "/sales",
        method: "GET",
    },

    /* ADMIN BILLING CONFIG */
    GET_ADMIN_CONFIG: {
        url: "/admin/config",
        method: "GET",
    },
    SAVE_ADMIN_CONFIG: {
        url: "/admin/config",
        method: "PUT",
    },
};

export { actions };
