// assets
// import { IconTruck } from "@tabler/icons";

// constant
// const icons = { IconTruck };

// ==============================|| DRIVER MENU ITEMS ||============================== //

const driver = {
    id: "driver-menu-items",
    title: "Órdenes de Trabajo",
    type: "group",
    children: [
        // {
        //     id: "work-orders-page",
        //     title: "Órdenes",
        //     type: "item",
        //     url: "/admin/work-orders",
        //     icon: icons.IconTruck,
        //     breadcrumbs: false,
        // },
    ],
};

export default driver;
