import PropTypes from "prop-types";

// material-ui
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const ChipGroup = ({ chips, chipProps, sx, ...props }) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, ...sx }} {...props}>
        {chips.map((value) => (
            <Chip key={value} label={value} size="small" {...chipProps} />
        ))}
    </Box>
);

ChipGroup.propTypes = {
    chips: PropTypes.array,
    chipProps: PropTypes.object,
};

export default ChipGroup;
