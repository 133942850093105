import { lazy } from "react";

// project imports
import Loadable from "components/Loadable";
import SimpleLayout from "layout/SimpleLayout";

// home option 1 routing
const MyProductPage = Loadable(lazy(() => import("views/pages/account/product/MyProduct")));
const EditProductPage = Loadable(lazy(() => import("views/pages/account/product/edit-product")));
const EditProfilePage = Loadable(lazy(() => import("views/pages/account/profile/edit-profile")));

// ==============================|| HOME ROUTING ||============================== //

const AccountRoutes = {
    path: "/user",
    element: <SimpleLayout privateRoute />,
    children: [
        {
            path: "/products",
            element: <MyProductPage />,
        },
        {
            path: "/product",
            element: <EditProductPage />,
        },
        {
            path: "/product/:productId",
            element: <EditProductPage />,
        },
        {
            path: "/profile",
            element: <EditProfilePage />,
        },
    ],
};

export default AccountRoutes;
