const ROLES = {
    user: "ROLE_USER",
    admin: "ROLE_ADMIN",
    seller: "ROLE_SELLER",
    driver: "ROLE_DRIVER",
};

const SCOPES = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view",
};

// const PERMISSIONS = {
//   [ROLES.user]: [SCOPES.canView],
//   [ROLES.admin]: [SCOPES.canView, SCOPES.canEdit],
//   [ROLES.owner]: [
//     SCOPES.canView,
//     SCOPES.canEdit,
//     SCOPES.canCreate,
//     SCOPES.canDelete
//   ]
// };

export { ROLES, SCOPES };
