// assets
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import { IconSteeringWheel, IconBusinessplan, IconNotes, IconReport } from "@tabler/icons";
import { IconNotes, IconSettings } from "@tabler/icons";

// constant
// const icons = { AssignmentIndIcon, IconSteeringWheel, IconBusinessplan, IconNotes, IconReport };
const icons = { IconNotes, IconSettings, AssignmentIndIcon };

// ==============================|| SELLER MENU ITEMS ||============================== //

const seller = {
    id: "seller-menu-items",
    title: "Gestión ventas",
    type: "group",
    children: [
        // {
        //     id: "customer-page",
        //     title: "Clientes",
        //     type: "item",
        //     url: "/admin/customers",
        //     icon: icons.AssignmentIndIcon,
        //     breadcrumbs: false,
        // },
        // {
        //     id: "drivers-page",
        //     title: "Conductores",
        //     type: "item",
        //     url: "/admin/drivers",
        //     icon: icons.IconSteeringWheel,
        //     breadcrumbs: false,
        // },
        {
            id: "orders-page",
            title: "Pedidos",
            type: "item",
            url: "/admin/orders",
            icon: icons.IconNotes,
            breadcrumbs: false,
        },
        // {
        //     id: "invoices-page",
        //     title: "Facturación",
        //     type: "item",
        //     url: "/admin/invoices",
        //     icon: icons.IconBusinessplan,
        //     breadcrumbs: false,
        // },
        // {
        //     id: "reports-collapse",
        //     title: "Informes",
        //     type: "collapse",
        //     icon: icons.IconReport,
        //     children: [
        //         {
        //             id: "geolocation-report",
        //             title: "Geolocalización",
        //             type: "item",
        //             url: "/admin/report/geolocation",
        //             breadcrumbs: false,
        //         },
        //     ],
        // },
        {
            id: "client-page",
            title: "Clientes",
            type: "item",
            url: "/admin/clients",
            icon: icons.AssignmentIndIcon,
            breadcrumbs: false,
        },
        // {
        //     id: "settings",
        //     title: "Configuración",
        //     type: "item",
        //     url: "/admin/settings",
        //     icon: icons.IconSettings,
        //     breadcrumbs: false,
        // },
    ],
};

export default seller;
