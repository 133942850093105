import { useEffect, useState } from "react";

// material-ui
import {
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Stack,
    Collapse,
    Divider,
    Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

// third party
import { Link, Element, scroller } from "react-scroll";

// project imports
import MainCard from "components/cards/MainCard";
import { gridSpacing } from "store/constant";
import { useGetRoles } from "hooks/user";
import { ROLES } from "constants/permission";

// assets
import {
    IconNotes,
    IconLicense,
    IconSearch,
    IconMap,
    IconTag,
    IconBuildingStore,
    IconHanger,
} from "@tabler/icons";

// section components
import * as HelpSection from "./HelpSections";

const userHelpSections = [
    {
        id: "myOrders",
        title: "Mis Pedidos",
        icon: <IconLicense />,
        section: <HelpSection.MyOrderHelpSection />,
    },
    {
        id: "nearbySearch",
        title: "Búsqueda por área",
        icon: <IconSearch />,
        section: <HelpSection.NearbySearchHelpSection />,
    },
    {
        id: "geolocation",
        title: "Geolocalización",
        icon: <IconMap />,
        section: <HelpSection.GeolocationHelpSection />,
    },
];

const sellerHelpSection = [
    {
        id: "orders",
        title: "Pedidos",
        icon: <IconNotes />,
        section: <HelpSection.OrderHelpSection />,
    },
    {
        id: "distancePrice",
        title: "Precio por distancia",
        icon: <IconTag />,
        section: <HelpSection.DistancePriceHelpSection />,
    },
    {
        id: "myProducts",
        title: "Productos",
        icon: <IconHanger />,
        section: <HelpSection.MyProductHelpSection />,
    },
    {
        id: "clients",
        title: "Clientes",
        icon: <AssignmentIndIcon />,
        section: <HelpSection.ClientHelpSection />,
    },
];

const HelpPage = () => {
    const theme = useTheme();
    const [openSellerSection, setOpenSellerSection] = useState(true);
    const [currentSection, setCurrentSection] = useState();

    const roles = useGetRoles();
    const isSeller = roles?.includes(ROLES.seller);

    useEffect(() => {
        const hash = document.location.href.split("#");
        if (hash.length > 1) {
            scroller.scrollTo(hash[1], {
                duration: 1000,
                delay: 100,
                smooth: true,
                offset: -105,
            });
            setCurrentSection(hash[1]);
        }
    }, []);

    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container spacing={gridSpacing} sx={{ minHeight: "260vh", maxWidth: "1400px" }}>
                <Grid item xs={12} md={4} lg={3} xl={3}>
                    <MainCard sx={{ position: "sticky", top: "109px" }}>
                        <Typography variant="h3" sx={{ padding: "4px", fontWeight: 500, fontSize: "1.15rem" }}>
                            Contenido de ayudas
                        </Typography>
                        <List
                            component="nav"
                            sx={{
                                width: "100%",
                                maxWidth: 350,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: "10px",
                                [theme.breakpoints.down("md")]: {
                                    minWidth: "100%",
                                },
                                "& .MuiListItemButton-root": {
                                    mt: 0.5,
                                },
                            }}
                        >
                            {userHelpSections.map((item) => (
                                <Link key={item.id} to={item.id} spy={true} smooth={true} offset={-105}>
                                    <ListItemButton
                                        key={item.id}
                                        sx={{ borderRadius: "7px" }}
                                        selected={currentSection === item.id}
                                        onClick={() => setCurrentSection(item.id)}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="body2">{item.title}</Typography>}
                                        />
                                    </ListItemButton>
                                </Link>
                            ))}

                            {isSeller && (
                                <>
                                    <ListItemButton
                                        onClick={() => setOpenSellerSection(!openSellerSection)}
                                        sx={{ borderRadius: "7px" }}
                                    >
                                        <ListItemIcon>
                                            <IconBuildingStore />
                                        </ListItemIcon>
                                        <ListItemText primary="Proveedor" />
                                        {openSellerSection ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Divider />
                                    <Collapse in={openSellerSection} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {sellerHelpSection.map((item) => (
                                                <Link
                                                    key={item.id}
                                                    to={item.id}
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-105}
                                                >
                                                    <ListItemButton
                                                        key={item.id}
                                                        sx={{ borderRadius: "7px", pl: 4 }}
                                                        selected={currentSection === item.id}
                                                        onClick={() => setCurrentSection(item.id)}
                                                    >
                                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    {item.title}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </Link>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </List>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={8} lg={9} xl={9}>
                    <MainCard sx={{ p: 3 }}>
                        <Stack spacing={gridSpacing}>
                            {[...userHelpSections, ...(isSeller ? sellerHelpSection : [])].map((item) => (
                                <Element name={item.id} key={item.id}>
                                    {item.section}
                                </Element>
                            ))}
                        </Stack>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HelpPage;
