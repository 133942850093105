import { lazy } from "react";

// project imports
import Loadable from "components/Loadable";
import SimpleLayout from "layout/SimpleLayout";

// home option 2 routing
const HomePage = Loadable(lazy(() => import("views/pages/home/Home")));
const ProductDetailPage = Loadable(lazy(() => import("views/pages/home/ProductDetail")));
const ProductCategoryPage = Loadable(lazy(() => import("views/pages/home/ProductCategory")));

// ==============================|| HOME ROUTING ||============================== //

const HomeRoutes = {
    path: "/home",
    element: <SimpleLayout />,
    children: [
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/product",
            element: <ProductDetailPage />,
        },
        {
            path: "/product/:productId",
            element: <ProductDetailPage />,
        },
        {
            path: "/category/:categoryId",
            element: <ProductCategoryPage />,
        },
    ],
};

export default HomeRoutes;
