import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";

// project imports
import config from "config";
import Breadcrumbs from "components/extended/Breadcrumbs";
import Header from "../MainLayout/Header";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";

// assets
import { IconChevronRight } from "@tabler/icons";

// styles
const Main = styled("main")(({ theme }) => ({
    ...theme.typography.mainContent,
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "16px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
    },
}));

// ==============================|| SIMPLE LAYOUT ||============================== //

const SimpleLayout = ({ privateRoute }) => {
    const theme = useTheme();
    const auth = useSelector((state) => state.authentication);

    if (privateRoute && !auth.isLogged) return <Navigate to={`${config.basename}/login`} />;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: "rgba(255,255,255, 0.85)", // theme.palette.background.default,
                    transition: "none",
                    backdropFilter: "blur(2px)",
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>

            {/* main content */}
            <Main theme={theme} open={true}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
        </Box>
    );
};

export default SimpleLayout;
