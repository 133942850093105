import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./reducers/customization";
import authenticationReducer from "./reducers/authentication";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authentication: authenticationReducer,
});

export default reducer;
