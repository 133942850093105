import { lazy } from "react";

// project imports
import Loadable from "components/Loadable";
import SimpleLayout from "layout/SimpleLayout";

// checkout option 2 routing
const CheckoutPage = Loadable(lazy(() => import("views/pages/checkout")));

// ==============================|| CHECKOUT ROUTING ||============================== //

const CheckoutRoutes = {
    path: "/home/product/:productId/checkout",
    element: <SimpleLayout privateRoute />,
    children: [
        {
            path: "/",
            element: <CheckoutPage />,
        },
    ],
};

export default CheckoutRoutes;
