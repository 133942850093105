import jwt_decode from "jwt-decode";

const SESSION_KEY = "session";

const setSession = (session) => localStorage.setItem(SESSION_KEY, session);

const removeSession = () => localStorage.removeItem(SESSION_KEY);

const recoverSession = () => localStorage.getItem(SESSION_KEY);

const decodeJWT = (token) => {
    try {
        return token ? jwt_decode(token) : {};
    } catch (error) {
        return {};
    }
};

export { setSession, removeSession, recoverSession, decodeJWT };
