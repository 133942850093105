// action - state management
import * as actionTypes from "../actions/types";
import { recoverSession } from "services/auth";

const token = recoverSession();

export const initialState = {
    accessToken: token,
    isLogged: Boolean(token),
};

// ==============================|| AUTHENTICATION REDUCER ||============================== //

const authenticationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.LOGIN:
            return { ...payload, isLogged: true };
        case actionTypes.LOGOUT:
            return { isLogged: false };
        default:
            return state;
    }
};

export default authenticationReducer;
