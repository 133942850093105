import { lazy } from "react";

// project imports
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 2 routing
const LoginPage = Loadable(lazy(() => import("views/pages/authentication/Login")));
const RegisterPage = Loadable(lazy(() => import("views/pages/authentication/Register")));
const VerifyPage = Loadable(lazy(() => import("views/pages/authentication/Verify")));
const ForgotPage = Loadable(lazy(() => import("views/pages/authentication/Forgot")));
const ResetPasswordPage = Loadable(lazy(() => import("views/pages/authentication/ResetPassword")));

const RedsysFormPage = Loadable(lazy(() => import("views/pages/checkout/RedsysForm")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "/register",
            element: <RegisterPage />,
        },
        {
            path: "/verify/:token",
            element: <VerifyPage />,
        },
        {
            path: "/password_reset",
            element: <ForgotPage />,
        },
        {
            path: "/password_reset/:token",
            element: <ResetPasswordPage />,
        },
        {
            path: "/redsys",
            element: <RedsysFormPage />,
        },
    ],
};

export default AuthenticationRoutes;
