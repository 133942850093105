// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useGetRoles } from "hooks/user";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const roles = useGetRoles();
    const navItems = menuItem.roleItems(roles).map((item) => {
        switch (item.type) {
            case "group":
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
