import { Navigate } from "react-router";

// project imports
import SimpleLayout from "layout/SimpleLayout";

// help routing
import HelpPage from "views/pages/help";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <SimpleLayout />,
    children: [
        {
            path: "/",
            element: <Navigate to="/home" />,
        },
        {
            path: "/help",
            element: <HelpPage />,
        },
    ],
};

export default MainRoutes;
