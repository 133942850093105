import dashboard from "./dashboard";

// project imports
import { ROLES } from "constants/permission";
import { getActionsFromRole } from "utils/role";

// menu-items
import user from "./roles/user";
import seller from "./roles/seller";
import admin from "./roles/admin";
import driver from "./roles/driver";

// ==============================|| SIDEBAR MENU ITEMS ||============================== //

const itemsRole = {
    ...(admin.children?.length > 0 && { [ROLES.admin]: admin }),
    ...(seller.children?.length > 0 && { [ROLES.seller]: seller }),
    ...(driver.children?.length > 0 && { [ROLES.driver]: driver }),
    ...(user.children?.length > 0 && { [ROLES.user]: user }),
};

const menuItems = {
    // items: [dashboard, pages, utilities, other]
    items: [dashboard],
    roleItems: (roles) => [dashboard, ...getActionsFromRole(itemsRole, roles)],
};

export default menuItems;
