import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const IconTypography = ({ startIcon, endIcon, children, containerSX, ...props }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px", ...containerSX }}>
            {startIcon}
            <Typography {...props}>{children}</Typography>
            {endIcon}
        </Box>
    );
};

export default IconTypography;
