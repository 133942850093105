import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
} from "@mui/material";

// project imports
import config from "config";
import { removeSession } from "services/auth";
import { LOGOUT } from "store/actions/types";
import { useGetRoles } from "hooks/user";
import { getActionsFromRole } from "utils/role";
import { mergueArray } from "utils/array";
import { ROLES } from "constants/permission";
import { stringAvatar } from "utils/avatar";

// components
import Transitions from "components/extended/Transitions";
import MainCard from "components/cards/MainCard";
import ChipGroup from "components/extended/ChipGroup";

// assets
import { IconLogout, IconSettings, IconHanger, IconLogin, IconUser, IconGridDots } from "@tabler/icons";

// ==============================|| PROFILE MENU ||============================== //

const rolesListItems = {
    COMMON: [
        {
            label: "Mi Perfil",
            route: `${config.basename}/user/profile`,
            icon: <IconUser stroke={1.5} size="1.3rem" />,
        },
        {
            label: "Mis Productos",
            route: `${config.basename}/user/products`,
            icon: <IconHanger stroke={1.5} size="1.3rem" />,
        },
        {
            label: "Cerrar Sesión",
            route: `${config.basename}/login`,
            icon: <IconLogout stroke={1.5} size="1.3rem" />,
            logout: true,
        },
    ],
    [ROLES.user]: [
        {
            label: "Panel de Control",
            // route: `${config.basename}/admin/dashboard`,
            route: `${config.basename}/admin/my-orders`,
            icon: <IconGridDots stroke={1.5} size="1.3rem" />,
        },
    ],
    [ROLES.admin]: [
        // {
        //     label: "Panel de Administración",
        //     route: `${config.basename}/admin/dashboard`,
        //     icon: <IconGridDots stroke={1.5} size="1.3rem" />,
        // },
    ],
};

const ProfileLabel = ({ theme, label }) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" color="black">
            {label}
        </Typography>
        <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
    </Stack>
);

const UnauthorizedLabel = ({ theme }) => (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ mx: 0.5 }}>
        <Typography variant="h4" color="black" sx={{ mx: 1 }}>
            Iniciar Sesión
        </Typography>
        <IconLogin stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
    </Stack>
);

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const roles = useGetRoles();

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = () => {
        removeSession();
        dispatch({ type: LOGOUT });
        navigate(`${config.basename}/login`);
        window.location.reload();
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, route = "") => {
        handleClose(event);

        if (route && route !== "") navigate(route);
    };

    const handleToggle = () => {
        if (auth.isLogged) setOpen((prevOpen) => !prevOpen);
        else navigate(`${config.basename}/login`);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const pathname = window.location.pathname;

    return (
        <>
            <Chip
                sx={{
                    height: "48px",
                    alignItems: "center",
                    borderRadius: "27px",
                    transition: "all .2s ease-in-out",
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        "& svg": {
                            stroke: theme.palette.primary.light,
                        },
                        "& h4": {
                            color: "white",
                        },
                    },
                    "& .MuiChip-label": {
                        lineHeight: 0,
                    },
                }}
                label={
                    auth.isLogged ? (
                        <ProfileLabel
                            theme={theme}
                            label={`${auth.firstName ?? "Cargando..."} ${auth.lastName ?? ""}`}
                        />
                    ) : (
                        <UnauthorizedLabel theme={theme} />
                    )
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                icon={
                    <Avatar
                        {...stringAvatar(`${auth?.firstName} ${auth?.lastName || ""}`)}
                        sx={{
                            ...stringAvatar(`${auth?.firstName} ${auth?.lastName || ""}`).sx,
                            ...theme.typography.mediumAvatar,
                            margin: "8px 0 8px 8px !important",
                            cursor: "pointer",
                            display: auth.isLogged ? "inherit" : "none",
                            color: "white !important",
                            fontSize: "1.05rem",
                        }}
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                    />
                }
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: { offset: [0, 14] },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box sx={{ m: 2 }}>
                                        <Stack spacing={0.85}>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Bienvenido,</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {`${auth?.firstName} ${auth?.lastName || ""}`}
                                                </Typography>
                                            </Stack>
                                            <Stack spacing={1} direction="row">
                                                <Typography variant="subtitle2" display="inline">
                                                    Kubas,
                                                </Typography>
                                                {auth?.roles && (
                                                    <ChipGroup
                                                        chips={auth.roles.map((item) => item.slice(5))}
                                                        chipProps={{
                                                            style: { fontSize: ".65rem", height: "18px" },
                                                        }}
                                                        sx={{ display: "inline-flex" }}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ m: 2 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: "100%",
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: "10px",
                                                [theme.breakpoints.down("md")]: {
                                                    minWidth: "100%",
                                                },
                                                "& .MuiListItemButton-root": {
                                                    mt: 0.5,
                                                },
                                            }}
                                        >
                                            {[
                                                ...mergueArray(getActionsFromRole(rolesListItems, roles)),
                                                ...rolesListItems.COMMON,
                                            ].map((item) => (
                                                <ListItemButton
                                                    key={item.label}
                                                    sx={{ borderRadius: "7px" }}
                                                    selected={pathname === item.route}
                                                    onClick={(event) => {
                                                        if (item.logout) {
                                                            handleLogout(item.route);
                                                        } else {
                                                            handleListItemClick(event, item.route);
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">{item.label}</Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
