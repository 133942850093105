// assets
import { IconUsers, IconSettings, IconMap } from "@tabler/icons";

// constant
const icons = { IconUsers, IconSettings, IconMap };

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: "admin-menu-items",
    title: "Administración",
    type: "group",
    children: [
        {
            id: "users-location",
            title: "Geolocalización",
            type: "item",
            url: "/admin/location-users",
            icon: icons.IconMap,
            breadcrumbs: false,
        },
        {
            id: "user-list",
            title: "Usuarios",
            type: "item",
            url: "/admin/users",
            icon: icons.IconUsers,
            breadcrumbs: false,
        },
        {
            id: "paltform-settings",
            title: "Configuración",
            type: "item",
            url: "/admin/platform-settings",
            icon: icons.IconSettings,
            breadcrumbs: false,
        },
    ],
};

export default admin;
