import axios from "axios";
// import { recoverSession } from "services/auth"; // TODO NO LEER DEL LOCALSTORAGE
import config from "config";

let store;

export const injectStore = (_store) => {
    store = _store;
};

const bearerTokenInterceptor = async (config) => {
    // const token = await recoverSession()?.accessToken;
    const { authentication } = store.getState();
    const token = authentication?.accessToken;

    if (token) {
        config.headers = { authorization: `Bearer ${token}` };
    }

    return config;
};

const apiServiceAxios = axios.create({ baseURL: config.baseURL });

apiServiceAxios.interceptors.request.use(bearerTokenInterceptor);

export { apiServiceAxios as axios };
