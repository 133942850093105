import config from "config";
import { Navigate } from "react-router-dom";

// ==============================|| NOT FOUND ROUTING ||============================== //

const NotFoundRoutes = {
    path: "*",
    element: <Navigate to={`${config.basename}/home`} />,
};

export default NotFoundRoutes;
