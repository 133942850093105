const getActionsFromRole = (actions, roles) => {
    if (!roles) return [];

    const result = [];
    for (let role of roles) {
        const action = actions[role];
        if (action) result.push(action);
    }
    return result;
};

export { getActionsFromRole };
