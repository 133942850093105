import { lazy } from "react";
import { Navigate } from "react-router";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";

// dashboard routing
const DashboardPage = Loadable(lazy(() => import("views/dashboard/default")));

// customer routing
const CustomerPage = Loadable(lazy(() => import("views/dashboard/customers")));
const CustomerFormPage = Loadable(lazy(() => import("views/dashboard/customers/CustomerForm")));

// order routing
const OrderPage = Loadable(lazy(() => import("views/dashboard/orders")));
const OrderFormPage = Loadable(lazy(() => import("views/dashboard/orders/OrderForm")));

// invoice routing
const InvoicePage = Loadable(lazy(() => import("views/dashboard/invoicing")));
const InvoiceFormPage = Loadable(lazy(() => import("views/dashboard/invoicing/InvoiceForm")));

// driver routing
const DriverPage = Loadable(lazy(() => import("views/dashboard/drivers")));
const DriverFormPage = Loadable(lazy(() => import("views/dashboard/drivers/DriverForm")));

// client routing
const ClientPage = Loadable(lazy(() => import("views/dashboard/clients")));
const ClientFormPage = Loadable(lazy(() => import("views/dashboard/clients/ClientForm")));

// report routing
const GeolocationReportPage = Loadable(
    lazy(() => import("views/dashboard/reports/geolocation/GeolocationReport"))
);

// ==============================|| SELLER ROUTING ||============================== //

const SellerRoutes = {
    path: "/admin",
    element: <MainLayout privateRoute />,
    children: [
        {
            path: "/",
            element: <Navigate to="/admin/dashboard" />,
        },
        {
            path: "/dashboard",
            element: <DashboardPage />,
        },
        {
            path: "/customers",
            element: <CustomerPage />,
        },
        {
            path: "/customer",
            element: <CustomerFormPage />,
        },
        {
            path: "/customer/:customerId",
            element: <CustomerFormPage />,
        },
        {
            path: "/orders",
            element: <OrderPage />,
        },
        {
            path: "/order",
            element: <OrderFormPage />,
        },
        {
            path: "/order/:orderId",
            element: <OrderFormPage />,
        },
        {
            path: "/clients",
            element: <ClientPage />,
        },
        {
            path: "/client/:clientId",
            element: <ClientFormPage />,
        },
        {
            path: "/drivers",
            element: <DriverPage />,
        },
        {
            path: "/driver",
            element: <DriverFormPage />,
        },
        {
            path: "/driver/:driverId",
            element: <DriverFormPage />,
        },
        {
            path: "/invoices",
            element: <InvoicePage />,
        },
        {
            path: "/invoice",
            element: <InvoiceFormPage />,
        },
        {
            path: "/invoice/:invoiceId",
            element: <InvoiceFormPage />,
        },
        {
            path: "/report/geolocation",
            element: <GeolocationReportPage />,
        },
    ],
};

export default SellerRoutes;
