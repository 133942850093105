import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "config";
import Logo from "components/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase
        disableRipple
        component={Link}
        to={`${config.basename}${config.defaultPath}`}
        sx={{ width: "100%", justifyContent: "flex-start", minHeight: "56px" }}
    >
        <Logo horizontal />
    </ButtonBase>
);

export default LogoSection;
