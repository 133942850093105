import { useRoutes } from "react-router-dom";

// project imports
import { useGetRoles } from "hooks/user";
import { ROLES } from "constants/permission";
import { getActionsFromRole } from "utils/role";

// config
import config from "config";

// routes
import AccountRoutes from "./AccountRoutes";
import MainRoutes from "./MainRoutes";
import CheckoutRoutes from "./CheckoutRoutes";
import HomeRoutes from "./HomeRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import NotFoundRoutes from "./NotFoundRoutes";

// role-routes
import UserRoutes from "./roles/UserRoutes";
import AdminRoutes from "./roles/AdminRoutes";
import SellerRoutes from "./roles/SellerRoutes";
import DriverRoutes from "./roles/DriverRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const ROLE_ROUTES = {
    [ROLES.user]: UserRoutes,
    [ROLES.admin]: AdminRoutes,
    [ROLES.seller]: SellerRoutes,
    [ROLES.driver]: DriverRoutes,
};

const ThemeRoutes = () => {
    const roles = useGetRoles();
    const RoleRoutes = getActionsFromRole(ROLE_ROUTES, roles) || {};

    return useRoutes(
        [
            MainRoutes,
            ...RoleRoutes,
            AuthenticationRoutes,
            HomeRoutes,
            CheckoutRoutes,
            AccountRoutes,
            NotFoundRoutes,
        ],
        config.basename
    );
};

export default ThemeRoutes;
