// assets
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// constant
// const icons = { AssignmentIndIcon, AttachMoneyIcon };

// ==============================|| USER MENU ITEMS ||============================== //

const user = {
    // id: "user-menu-items",
    // title: "Gestión",
    // type: "group",
    // children: [
    //     {
    //         id: "customer-page",
    //         title: "Clientes",
    //         type: "item",
    //         url: "/admin/customers",
    //         icon: icons.AssignmentIndIcon,
    //         breadcrumbs: false,
    //     },
    //     {
    //         id: "orders-page",
    //         title: "Pedidos",
    //         type: "item",
    //         url: "/admin/orders",
    //         icon: icons.AttachMoneyIcon,
    //         breadcrumbs: false,
    //     },
    // ],
};

export default user;
